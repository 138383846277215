import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import {
  getAPICarriersUrl,
  getApiCarrierAccountsUrl,
  updateLocalCarrierTypeUrl,
  getAllCustomerGroupsUrl,
  getAllZonesProfileUrl,
} from "../library/URLs";
import { useEffect, useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import {
  MultiSelectBox,
  SelectBox,
  TextBox,
  TextFieldBox,
  UploadImgField,
} from "../components/InputFields";
import { BsX } from "react-icons/bs";
import { SessionManagement } from "../library/SessionManagement";

const SubmitButton = styled.button``;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const UpdateLocalCarrierTypeDialog = ({
  isModalVisible,
  handleCloseDialog,
  carrier_details,
}) => {
  const companyLogoURL = SessionManagement.getCompanyLogoURL();
  const companyName = SessionManagement.getCompanyName();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiCarriers, setApiCarriers] = useState([]);
  const [carrierAccounts, setCarrierAccounts] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [customerGroups, setCustomerGroups] = useState([]);
  const [zoneProfiles, setZoneProfiles] = useState([]);

  const [carrierForm, setCarrierForm] = useState({
    calculation_connection_type: "",
    api_type: "",
    api_account_id: "",
    transit_time: "",
    display_name: "",
    visibility_type: "",
    zone_profile_id: "",
    pricing_type: "",
    additional_info: "",
    image_url: companyLogoURL,
    groups: [],
  });
  const [logoFile, setLogoFile] = useState(companyName);

  useEffect(() => {
    if (carrier_details) {
      setCarrierForm({
        ...carrierForm,
        carrier_id: carrier_details?.id,
        shipment_type: carrier_details?.shipment_type,
        calculation_connection_type:
          carrier_details?.calculation_connection_type,
        api_type: carrier_details?.api_type,
        pricing_type: carrier_details?.pricing_type,
        api_account_id: carrier_details?.api_account_id
          ? parseInt(carrier_details?.api_account_id)
          : carrier_details?.api_account_id,
        transit_time: carrier_details?.transit_time,
        visibility_type: carrier_details?.visibility_type,
        display_name: carrier_details?.masked_name,
        additional_info: carrier_details?.additional_info,
        image_url: carrier_details?.image_url,
      });

      if (carrier_details?.api_type) {
        getCarrierAccounts(carrier_details?.api_type);
      }

      if (carrier_details?.image_url) {
        setLogoFile(carrier_details?.masked_name);
      }

      fetchApiCarriers();
      getCustomerGroups();
      fetchAllZoneProfiles();
    }
    // eslint-disable-next-line
  }, [carrier_details]);

  const getCarrierAccounts = async (carrier_id) => {
    const formValues = {
      ...UserProfileData(),
      carrier: carrier_id,
    };
    setFetching(true);

    await axios
      .post(getApiCarrierAccountsUrl, formValues)
      .then((res) => {
        setFetching(false);
        if (res?.data?.success) {
          let carrier_accounts = res?.data?.data?.map((carrier) => {
            return {
              label: carrier?.name,
              value: carrier?.id,
            };
          });

          setCarrierAccounts(carrier_accounts);
        }
      })
      .catch((err) => {
        setFetching(false);
      });
  };

  const getCustomerGroups = async () => {
    const formValues = {
      ...UserProfileData(),
    };

    await axios
      .post(getAllCustomerGroupsUrl, formValues)
      .then((res) => {
        setFetching(false);
        if (res?.data?.success) {
          let groups = [{ label: "None", value: "" }];

          res?.data?.data?.forEach((group) => {
            groups.push({
              label: group?.name,
              value: group?.id,
            });
          });
          if (carrierForm?.group_id && carrierForm?.group_id !== "") {
            let selected_groups_id = carrierForm?.group_id?.split("|");
            let selected_groups = res?.data?.data
              ?.filter((group) => selected_groups_id?.includes(`${group?.id}`))
              ?.map((group) => {
                return {
                  label: group?.name,
                  value: group?.id,
                };
              });
            setCarrierForm({
              ...carrierForm,
              groups: selected_groups,
            });
          }
          setCustomerGroups(groups);
        }
      })
      .catch((err) => {});
  };

  const fetchAllZoneProfiles = async () => {
    await axios
      .post(getAllZonesProfileUrl, UserProfileData())
      .then((res) => {
        if (res?.data?.success) {
          let all_profiles = res?.data?.data?.map((profile) => {
            return {
              label: profile?.name,
              value: profile?.id,
            };
          });
          setZoneProfiles(all_profiles);
        }
      })
      .catch((err) => {});
  };

  const fetchApiCarriers = async () => {
    await axios
      .post(getAPICarriersUrl, UserProfileData())
      .then((res) => {
        if (res?.data?.success) {
          if (carrierForm?.shipment_type) {
            let api_carriers = res?.data?.data
              ?.filter(
                (carrier) =>
                  carrier[carrierForm?.shipment_type?.toLowerCase()] === "Yes"
              )
              ?.map((carrier) => {
                return {
                  label: carrier?.name,
                  value: carrier?.id,
                  img_url: carrier?.image_url,
                };
              });

            setApiCarriers(api_carriers);
          }
        }
      })
      .catch((err) => {});
  };

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleInput = (e) => {
    const { name, value } = e.target;

    if (name === "api_type") {
      setCarrierForm({
        ...carrierForm,
        [name]: value,
        image_url: apiCarriers?.find((api) => `${api?.value}` === value)
          ?.img_url,
      });
      setLogoFile(apiCarriers?.find((api) => `${api?.value}` === value)?.label);
      getCarrierAccounts(value);
    } else {
      setCarrierForm({
        ...carrierForm,
        [name]: value,
      });
    }
  };

  const connection_type_options = [
    {
      label: "Using my delivery agents. e.g. riders and drivers",
      value: "Self",
    },
    { label: "Using third party logistics (3PL) APIs ", value: "API" },
  ];

  const onSubmit = (e) => {
    e.preventDefault();

    const formdata = {
      ...UserProfileData(),
      ...carrierForm,
      carrier: carrierForm?.api_type,
      group_id: carrierForm.groups?.map((group) => group.value).join("|"),
    };
    setIsSubmitting(true);

    axios
      .post(updateLocalCarrierTypeUrl, formdata)
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.success) {
          window.scrollTo(0, 0);

          setSuccessVisibility(true);
          setSuccessMessage("Carrier updated successfully!");

          setTimeout(() => {
            setSuccessVisibility(false);
          }, 3000);
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        } else {
          window.scrollTo(0, 0);
          setErrorVisibility(true);
          setErrorMessage(res?.data?.message);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const handleUploadLogo = (url) => {
    setCarrierForm({
      ...carrierForm,
      image_url: url,
    });
  };

  // console.log("dfa ", carrierForm);
  const visibility_options = [
    { label: "Admins and Users", value: "All" },
    { label: "Admins only", value: "Admin" },
    { label: "Users only", value: "User" },
  ];

  const pricing_options = [
    { label: "By Distance", value: "Distance" },
    { label: "By Zone", value: "Zone" },
    { label: "Use Flat Fee", value: "Flat Fee" },
  ];

  const handleSelectCustomerGroup = (value) => {
    if (value.length > 0) {
      if (value[value.length - 1]?.value === "") {
        setCarrierForm({
          ...carrierForm,
          groups: [{ label: "None", value: "" }],
        });
      } else {
        setCarrierForm({
          ...carrierForm,
          groups: value.filter((group) => group.value !== ""),
        });
      }
    } else {
      setCarrierForm({
        ...carrierForm,
        groups: value,
      });
    }
  };

  return (
    <Modal size="lg" show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">UPDATE LOCAL CARRIER</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <p>Fill the details below to add a carrier type</p>
        <div className="query-result-container">
          <Row className="mb-2 gy-4">
            <Col xs={12} lg={8}>
              <div className="">
                <label htmlFor="Description" className="mb-1">
                  Display Name
                </label>
                <TextBox
                  type="text"
                  name="display_name"
                  value={carrierForm.display_name}
                  onChange={handleInput}
                  placeholder="Enter carrier display name"
                />
              </div>
            </Col>
            <Col xs={12} lg={4}>
              <div>
                <label className="mb-1" htmlFor="Description">
                  Transit Time
                </label>
                <TextBox
                  name="transit_time"
                  type="text"
                  value={carrierForm.transit_time}
                  onChange={handleInput}
                  placeholder="E.g: 2 to 3 working days"
                />
              </div>
            </Col>
            <Col
              md={12}
              lg={carrierForm.calculation_connection_type === "Self" ? 6 : 12}
            >
              <div>
                <label htmlFor="Description" className="mb-1">
                  How do you want your shipment booked?
                </label>
                <SelectBox
                  name="calculation_connection_type"
                  value={carrierForm?.calculation_connection_type}
                  options={connection_type_options}
                  onChange={handleInput}
                  placeholder="Enter connection type"
                />
              </div>
            </Col>

            {carrierForm.calculation_connection_type === "Self" && (
              <Col md={6}>
                <div>
                  <label htmlFor="Description" className="mb-1">
                    How do you want to set pricing?
                  </label>
                  <SelectBox
                    name="pricing_type"
                    value={carrierForm?.pricing_type}
                    options={pricing_options}
                    onChange={handleInput}
                    placeholder="Select pricing type"
                  />
                </div>
              </Col>
            )}

            {carrierForm?.pricing_type === "Zone" && (
              <Col xs={12} lg={12}>
                <div className="">
                  <label htmlFor="Description" className="mb-1">
                    Select Zone Profile of your Choice
                  </label>
                  <SelectBox
                    name="zone_profile_id"
                    value={carrierForm.zone_profile_id}
                    options={zoneProfiles}
                    onChange={handleInput}
                    placeholder="Select zone profile"
                  />
                </div>
              </Col>
            )}

            {carrierForm?.calculation_connection_type === "API" && (
              <Col md={6}>
                <div>
                  <label htmlFor="Description" className="mb-1">
                    Select API to use
                  </label>
                  <SelectBox
                    name="api_type"
                    value={carrierForm?.api_type}
                    options={apiCarriers}
                    onChange={handleInput}
                    placeholder="Select API"
                  />
                </div>
              </Col>
            )}
            {carrierForm?.calculation_connection_type === "API" && (
              <Col md={6}>
                <div>
                  <label htmlFor="Description" className="mb-1">
                    Select API account to use
                  </label>
                  <div className="d-flex gap-2 align-items-center">
                    {fetching && (
                      <span className="spinner-border spinner-grow-sm" />
                    )}
                    <SelectBox
                      name="api_account_id"
                      value={carrierForm?.api_account_id}
                      options={carrierAccounts}
                      onChange={handleInput}
                      placeholder="Select api account"
                    />
                  </div>
                </div>
              </Col>
            )}
            <Col xs={12}>
              <div className="">
                <label htmlFor="package img" className="mb-0">
                  Upload Carrier Logo
                </label>
                <UploadImgField
                  handleInput={handleUploadLogo}
                  fileName={logoFile}
                  setFileName={setLogoFile}
                  imageUrl={carrierForm?.image_url}
                />
              </div>
            </Col>
            <Col xs={12}>
              <div className="mb-2">
                <label htmlFor="Description" className="mb-1">
                  Additional Information
                </label>
                <TextFieldBox
                  name="additional_info"
                  value={carrierForm.additional_info}
                  onChange={handleInput}
                  placeholder="Enter additional information"
                />
              </div>
            </Col>

            <Col md={6}>
              <div>
                <label htmlFor="Description" className="mb-1">
                  Who should see this pricing?
                </label>
                <SelectBox
                  name="visibility_type"
                  value={carrierForm?.visibility_type}
                  options={visibility_options}
                  onChange={handleInput}
                  placeholder="Select options"
                />
              </div>
            </Col>

            <Col xs={6}>
              <div>
                <label htmlFor="Description" className="mb-1">
                  Which group is eligible to this carrier?
                </label>
                <MultiSelectBox
                  name="selected_shipping_options"
                  value={carrierForm?.groups}
                  options={customerGroups}
                  onChange={(value) => handleSelectCustomerGroup(value)}
                  placeholder="Select shipping plan"
                />
              </div>
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-secondary mx-3" onClick={handleCloseDialog}>
          <BsX />
          Cancel
        </button>
        <SubmitButton onClick={onSubmit} type="submit" className="btn-primary">
          {isSubmitting === false ? (
            <span className="button-text d-flex align-items-center gap-2">
              Proceed
            </span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateLocalCarrierTypeDialog;
