// const domain = "https://admin.parcelflow.io/engine/v1/actions/";
const domain = "https://parcelflow-admin-767a056c40df.herokuapp.com/engine/v1/actions/";
// const domain = "https://parcelflow-admin-staging-f10dbf53d922.herokuapp.com/engine/v1/actions/";

export const loginURL = domain + "login.php";
export const registerURL = domain + "register.php";
export const logoutURL = domain + "logout";
export const requestOTPURL = domain + "send-verification-code.php";
export const verifyOTPURL = domain + "create-account";
export const updateOperatorURL = domain + "update-operator-type.php";
export const createInternationalShipmentURL = domain + "create-inter-shipment.php";
export const createInternationalShipmentNewURL = domain + "create-inter-shipment-new.php";
export const createTerminalInterShipment = domain + "create-inter-shipment-terminal.php";
export const getDashboardStatisticsURL = domain + "get-dashboard-stats";
export const getTransactionsURL = domain + "get-transactions";
export const getShipmentsURL = domain + "get-shipments-new-1";
export const getShipmentReportURL = domain + "get-report-shipments.php";
export const getCustomersURL = domain + "get-all-users";
export const getShipmentTimelineURL = domain + "get-shipment-timeline-x";
export const getShipmentPath = domain + "get-shipment-path";
export const getShipmentDetailsURL = domain + "get-shipment-by-shipment-id";
export const getRecentTransporterActivitiesURL = domain + "get-rider-activities";
export const getAllTransporterActivitiesURL = domain + "get-all-riders-activities";
export const getAllUsersActivitiesURL = domain + "get-all-users-activities";
export const getCompanyWalletDetails = domain + "get-virtual-account";
export const createCompanyWalletDetails = domain + "create-virtual-account";
export const approveShipmentURL = domain + "approve-international-shipment.php";
export const getCustomerDetailsURL = domain + "get-user-by-user-id";
export const makeContractUserURL = domain + "make-contract-user";
export const makeContractUser2URL = domain + "make-contract-user-2";
export const makeRegularUserURL = domain + "make-regular-user";
export const getShipmentsByUserURL = domain + "get-shipments-by-user-id";
export const searchForCustomersURL = domain + "search-for-users";
export const getContractDetailsUserURL = domain + "get-contract-details-by-user";
export const getDHLPickupLocationsURL = domain + "get-pickup-details";
export const getPackagingURL = domain + "get-packaging";
export const calculateDHLURL = domain + "calculate-shipment";
export const calculateTerminalURL = domain + "calculate-terminal-shipment";
export const getAllAdminsURL = domain + "get-all-employees";
export const cancelShipmentURL = domain + "cancel-shipment";
export const updateShipmentStatusURL = domain + "update-shipment-status";
export const getAddressSuggestionsURL = domain + "get-address-suggestions";
export const calculateLocalCostURL = domain + "calculate-shipment-cost";
export const createLocalShipmentURL = domain + "create-shipment";
export const getAllTransportersURL = domain + "get-all-riders";
export const rerouteShipmentURL = domain + "reroute-shipment";
export const routeShipmentURL = domain + "route-shipment";
export const topupUserWalletURL = domain + "topup-user-wallet";
export const deductUserWalletURL = domain + "deduct-wallet-balance";
export const markShipmentAsPaidURL = domain + "mark-shipment-as-paid";
export const getCustomerWalletHistoryURL = domain + "get-wallet-history";
export const getCustomerWalletDetailsURL = domain + "get-wallet-balance";
export const getAdminDetailsURL = domain + "get-employee-by-employee-id";
export const getCustomerStatsURL = domain + "get-user-stats";
export const getCustomerReferredUsersURL = domain + "get-user-referred-users";
export const getCustomerReferralDetailsURL = domain + "get-user-referral-details";
export const getTransactionDetailsURL = domain + "get-transaction-details";
export const getUnseenNotificationsURL = domain + "get-unseen-notifications";
export const getNotificationsURL = domain + "get-notifications";
export const markNotificationsAsReadURL = domain + "mark-notifications-as-read";
export const getUserReportsURL = domain + "get-user-reports";
export const getTransporterReportsURL = domain + "get-transporter-reports";
export const createAdminURL = domain + "create-employee";
export const uploadImageURL = "https://user.parcelflow.site/engine/v1/actions/upload-profile-image";
export const createTransporterURL = domain + "transporters/create-transporter";
export const editAdminPrivilegesURL = domain + "edit-employee-privileges";
export const getAdminActivitiesURL = domain + "get-employee-activities";
export const getAdminPrivilegesURL = domain + "get-employee-privileges";
export const getShipmentPricingURL = domain + "get-price-settings";
export const changePasswordURL = domain + "change-password";
export const updateProfileURL = domain + "update-profile";
export const deleteEmployeeURL = domain + "delete-employee";
export const requestResetCodeURL = domain + "request-reset-password-code";
export const resetPasswordURL = domain + "reset-password";
export const getWhatsappChatListURL = domain + "get-whatsapp-chat-list";
export const sendWhatsappMessageURL = domain + "send-whatsapp-message";
export const getConversationMessagesURL = domain + "get-whatsapp-conversation-messages";
export const validateAddressURL = domain + "validate-address";
export const getCompanySettingsURL = domain + "get-company-settings";
export const getStatesURL = domain + "get-states";
export const getCitiesURL = domain + "get-cities";
export const updateAccountSettingsURL = domain + "update-account-settings";
export const getCompanySettings1URL = domain + "get-company-settings1";
export const searchDHLCityURL = domain + "search-dhl-cities";
export const getAllCustomerWalletURL = domain + "get-all-users-wallet";
export const searchForShipmentsURL = domain + "search-simple-for-shipments";
export const getInterstate3PLPricingURL = domain + "get-interstate-3pl-pricing";
export const updateInterstate3PLPricingURL = domain + "set-interstate-3pl-pricing";
export const getInternational3PLPricingURL = domain + "get-international-3pl-pricing";
export const updateInternational3PLPricingURL = domain + "set-international-3pl-pricing";
export const getStationsURL = domain + "get-stations";
export const getTransporterDetailsURL = domain + "get-rider-by-rider-id";
export const deleteTransporterURL = domain + "delete-rider";
export const deactivateTransporterURL = domain + "suspend-rider";
export const activateTransporterURL = domain + "activate-rider";
export const editTransporterURL = domain + "transporters/edit-transporter-details";
export const resetTransporterPasswordURL = domain + "reset-rider-password";
export const getAramexAccountURL = domain + "get-aramex-account";
export const getAllMarketersURL = domain + "get-all-marketers";
export const createMarketerURL = domain + "create-marketer";
export const getWhatsappTemplatesURL = domain + "get-whatsapp-templates";
export const createWhatsappTemplatesURL = domain + "create-whatsapp-template";
export const deleteWhatsappTemplatesURL = domain + "delete-whatsapp-template";
export const createWhatsappAccountURL = domain + "create-whatsapp-account";
export const registerWhatsappPhoneURL = domain + "register-whatsapp-phone-number";
export const getWhatsappNumbersURL = domain + "get-whatsapp-phone-numbers";
export const getShipmentPackagesURL = domain + "get-shipment-packages";
export const getPendingKycUrl = domain + "get-pending-kycs.php";
export const approveKycUrl = domain + "approve-kyc.php";
export const rejectKycUrl = domain + "reject-kyc.php";
export const getWalletReportUrl = domain + "create-wallet-report.php";
export const getCustomerKYCDocUrl = domain + "get-customer-kyc.php";
export const activateUserUrl = domain + "activate-user";
export const deactivateUserUrl = domain + "deactivate-user";
export const suspendUserUrl = domain + "suspend-user";
export const unsuspendUserUrl = domain + "unsuspend-user";
export const createStationUrl = domain + "create-station";
export const editStationUrl = domain + "edit-station";
export const deleteStationUrl = domain + "delete-station";
export const updateShipmentSettingsUrl = domain + "update-shipment-settings";
export const getShipmentSettingsUrl = domain + "get-shipment-settings-new.php";
export const advanceShipmentSearchUrl = domain + "search-advance-for-shipments";
export const uploadMediaUrl = "https://user.parcelflow.site/engine/v1/actions/upload-profile-image";
export const updateAppInfoUrl = domain + "update-app-info.php";
export const getAppInfoUrl = domain + "get-app-info";
export const getShipmentLevelsUrl = domain + "get-shipment-levels";
export const addPackagingUrl = domain + "add-packaging";
export const editPackagingUrl = domain + "edit-packaging";
export const deletePackagingUrl = domain + "delete-packaging";
export const editAdminUrl = domain + "edit-employee-details";
export const updatePriceSettingsUrl = domain + "update-price-settings";
export const updatePaystackDetailsUrl = domain + "update-paystack-details";
export const getPaystackDetailsUrl = domain + "get-paystack-details";
export const getNotificationSettingsUrl = domain + "get-notification-settings";
export const setNotificationSettingsUrl = domain + "set-notification-setting";
export const massUpdateShipmentStatusUrl = domain + "mass-update-status";
export const calculatePickupCostUrl = domain + "shipments/calculate-pickup-cost";
export const generateInvoiceUrl = "https://parcelflow-user-b4e5130303b2.herokuapp.com/engine/v1/actions/create-shipment-invoice";
export const getAdditionalServiceUrl = domain + "get-additional-services";
export const getShipmentServicesUrl = domain + "get-shipment-additional-services";
export const uploadPackageImgUrl = "https://admin.parcelflow.site/engine/v1/actions/upload-package-image";
export const getCompanyInsuranceUrl = domain + "get-company-insurance";
export const getDashboardShipmentStatsUrl = domain + "get-shipments-analysis";
export const searchEmployeesUrl = domain + "search-employees";
export const calculateEstimatesUrl = domain + "get-shipment-estimate";
export const getStates2Url = domain + "get-states-2";
export const getCitiesByStateNameUrl = domain + "get-cities-2";
export const getCustomerVirtualAccountUrl = domain + "get-customer-account-number";
export const getAllCarriersUrl = domain + "get-all-carriers";
export const getCarrierByIDUrl = domain + "get-carrier-by-id";
export const getAPICarriersUrl = domain + "get-api-carriers";
export const addCarrierTypeUrl = domain + "add-carrier-type";
export const updateCarrierTypeUrl = domain + "update-carrier-type";
export const updateInternationalCarrierTypeUrl = domain + "update-international-carrier-type";
export const deactivateCarrierUrl = domain + "deactivate-carrier";
export const activateCarrierUrl = domain + "activate-carrier";
export const updatePriceMarginsUrl = domain + "update-price-margins";
export const getMarginByCarrierUrl = domain + "get-price-margin-by-carrier";
export const createInterShipmentsUrl = domain + "create-inter-shipment-2";
export const calculateLocalShipmentUrl = domain + "calculate-local-cost";
export const createLocalShipmentUrl = domain + "create-local-shipment";
export const getSalesReportsUrl = domain + "calculate-shipment-profit";
export const getInsuranceFeeUrl = domain + "insurance/get-insurance-fee";
export const switchEnvironmentUrl = domain + "switch-environment";
export const getRiderSettingsUrl = domain + "get-rider-settings";
export const setRiderSettingsUrl = domain + "set-rider-settings";
export const getPaymentMethodsUrl = domain + "get-payment-methods";
export const setPaymentMethodsUrl = domain + "update-payment-methods";
export const getShipmentSettings2Url = domain + "get-shipment-settings";
export const editShipmentSettings2Url = domain + "edit-shipment-settings";
export const addAdditionalServiceUrl = domain + "add-shipment-additional-service";
export const editAdditionalServiceUrl = domain + "update-shipment-additional-service";
export const deleteAdditionalServiceUrl = domain + "delete-shipment-additional-service";
export const getStripeDetailsUrl = domain + "get-stripe-details";
export const updateStripeDetailsUrl = domain + "update-stripe-details";
export const updateInsuranceInfoUrl = domain + "update-insurance-info";
export const getDHLAccountsUrl = domain + "carriers/get-dhl-accounts";
export const addDHLAccountUrl = domain + "carriers/add-dhl-account";
export const updateDHLAccountUrl = domain + "carriers/update-dhl-account";
export const deleteDHLAccountUrl = domain + "carriers/delete-dhl-account";
export const getFedexAccountsUrl = domain + "carriers/get-fedex-accounts";
export const addFedexAccountUrl = domain + "carriers/add-fedex-account";
export const updateFedexAccountUrl = domain + "carriers/update-fedex-account";
export const deleteFedexAccountUrl = domain + "carriers/delete-fedex-account";
export const getAramexAccountsUrl = domain + "carriers/get-aramex-accounts";
export const addAramexAccountUrl = domain + "carriers/add-aramex-account";
export const updateAramexAccountUrl = domain + "carriers/update-aramex-account";
export const deleteAramexAccountUrl = domain + "carriers/delete-aramex-account";
export const getUPSAccountsUrl = domain + "carriers/get-ups-accounts";
export const addUPSAccountUrl = domain + "carriers/add-ups-account";
export const updateUPSAccountUrl = domain + "carriers/update-ups-account";
export const deleteUPSAccountUrl = domain + "carriers/delete-ups-account";
export const uploadRateDocumentUrl = "https://admin.parcelflow.site/engine/v1/actions/upload-spreadsheet-rate";
export const getApiCarrierAccountsUrl = domain + "get-api-accounts";
export const validateRegisterFormUrl = domain + "auth/validate-registration";
export const newRegisterUrl = domain + "auth/register";
export const getDashboardStatsUrl = domain + "get-dashboard-stats2";
export const toggleStatsVisibilityUrl = domain + "toggle-dashboard-stats-visibility";
export const getLocalMarginsUrl = domain + "get-local-price-margins";
export const updateLocalMarginsUrl = domain + "update-local-price-margins";
export const addLocalCarrierTypeUrl = domain + "add-local-carrier-type";
export const updateLocalCarrierTypeUrl = domain + "update-local-carrier-type";
export const calculateNewInterShipmentsUrl = domain + "calculate-shipment-x";
export const createNewInterShipmentsUrl = domain + "create-inter-shipment-x";
export const getCarrierShippingPlansUrl = domain + "carriers/get-available-shipping-plans.php";
export const getTopshipAccountsUrl = domain + "carriers/get-topship-accounts.php";
export const addTopshipAccountUrl = domain + "carriers/add-topship-account";
export const updateTopshipAccountUrl = domain + "carriers/update-topship-account";
export const deleteTopshipAccountUrl = domain + "carriers/delete-topship-account";
export const getDellymanAccountsUrl = domain + "carriers/get-dellyman-accounts.php";
export const addDellymanAccountUrl = domain + "carriers/add-dellyman-account";
export const updateDellymanAccountUrl = domain + "carriers/update-dellyman-account";
export const deleteDellymanAccountUrl = domain + "carriers/delete-dellyman-account";
export const getRedstarAccountsUrl = domain + "carriers/get-redstar-accounts.php";
export const addRedstarAccountUrl = domain + "carriers/add-redstar-account";
export const updateRedstarAccountUrl = domain + "carriers/update-redstar-account";
export const deleteRedstarAccountUrl = domain + "carriers/delete-redstar-account";
export const getTerminalAccountsUrl = domain + "carriers/get-terminal-accounts.php";
export const addTerminalAccountUrl = domain + "carriers/add-terminal-account";
export const updateTerminalAccountUrl = domain + "carriers/update-terminal-account";
export const deleteTerminalAccountUrl = domain + "carriers/delete-terminal-account";
export const getFezAccountsUrl = domain + "carriers/get-fez-accounts.php";
export const addFezAccountUrl = domain + "carriers/add-fez-account";
export const updateFezAccountUrl = domain + "carriers/update-fez-account";
export const deleteFezAccountUrl = domain + "carriers/delete-fez-account";
export const getAnkaAccountsUrl = domain + "carriers/get-anka-accounts.php";
export const addAnkaAccountUrl = domain + "carriers/add-anka-account";
export const updateAnkaAccountUrl = domain + "carriers/update-anka-account";
export const deleteAnkaAccountUrl = domain + "carriers/delete-anka-account";
export const getKwikAccountsUrl = domain + "carriers/get-kwik-accounts.php";
export const addKwikAccountUrl = domain + "carriers/add-kwik-account";
export const updateKwikAccountUrl = domain + "carriers/update-kwik-account";
export const deleteKwikAccountUrl = domain + "carriers/delete-kwik-account";
export const getBatchDetailsUrl = domain + "batches/get-batch-by-number";
export const addShipmentsToBatchUrl = domain + "batches/add-shipments-to-batch";
export const getAllBatchUrl = domain + "batches/get-all-batches";
export const searchBatchUrl = domain + "batches/search-batch-numbers";
export const getBatchShipmentsUrl = domain + "batches/get-all-batch-shipments";
export const getBatchTimelineUrl = domain + "batches/get-batch-timeline";
export const approveBatchUrl = domain + "batches/approve-batch";
export const acceptBatchUrl = domain + "batches/accept-batch";
export const cancelBatchUrl = domain + "batches/cancel-batch.php";
export const removeBatchShipmentUrl = domain + "batches/remove-shipments-from-batch";
export const getAllCarrierCountriesUrl = domain + "get-carrier-countries";
export const insureShipmentUrl = domain + "insurance/insure-shipment";
export const createOperatorWalletUrl = domain + "operators/create-wallet-account";
export const getWalletBalanceUrl = domain + "operators/get-wallet-balance";
export const getWalletTransactionsUrl = domain + "operators/get-wallet-transactions";
export const getWalletDetailsUrl = domain + "operators/get-wallet-details";
export const addShipmentTrackingDetailsUrl = domain + "add-shipment-tracking-details";
export const getShipmentTrackingDetailsUrl = domain + "get-shipment-tracking-details";
export const createUserUrl = domain + "create-user";
export const uploadWaybillURL = "https://admin.parcelflow.site/engine/v1/actions/upload-waybill";
export const updateShipmentInfoUrl = domain + "update-shipment-info";
export const deleteCarrierUrl = domain + "delete-carrier-by-id";
export const getCargoAirlinesUrl = domain + "carriers/get-cargo-airlines";
export const saveAddressUrl = domain + "address-book/save-address";
export const getAddressBookUrl = domain + "address-book/get-addresses";
export const deleteAddressUrl = domain + "address-book/delete-address";
export const updateAddressUrl = domain + "address-book/update-address";
export const getInsuredShipmentsUrl = domain + "insurance/get-insured-shipments";
export const getStationsByStateUrl = domain + "get-stations-by-state";
export const getTransporterByStateUrl = domain + "transporters/get-all-transporters-by-state";
export const dispatchBatchShipmentsUrl = domain + "batches/dispatch-shipments";
export const getStationShipmentsUrl = domain + "get-shipments-by-station";
export const getStationEmployeesUrl = domain + "get-station-employees";
export const getAllCustomerGroupsUrl = domain + "customers/get-all-groups";
export const createCustomerGroupUrl = domain + "customers/create-customer-group";
export const addCustomerToGroupUrl = domain + "customers/add-customer-to-group";
export const removeCustomerFromGroupUrl = domain + "customers/remove-customer-from-group";
export const getCustomerGroupMembersUrl = domain + "customers/get-group-members";
export const getCustomerGroupDetailsUrl = domain + "customers/get-group-details";
export const getGroupByCustomerIDUrl = domain + "customers/get-customer-groups";
export const createZoneUrl = domain + "zones/create-zone";
export const updateZoneUrl = domain + "zones/update-zone";
export const deleteZoneUrl = domain + "zones/delete-zone";
export const getZoneDetailsUrl = domain + "zones/get-zone-details";
export const getAllZonesUrl = domain + "zones/get-all-zones";
export const getZonePricingUrl = domain + "zones/get-zone-pricing";
export const setZonePricingUrl = domain + "zones/set-zone-pricing";
export const getCostSettingsUrl = domain + "settings/get-cost-settings";
export const setCostSettingsUrl = domain + "settings/set-cost-settings";
export const getAllZonePricingUrl = domain + "zones/get-all-zones-pricing";
export const addInterstateCarrierTypeUrl = domain + "add-interstate-carrier-type";
export const updateInterstateCarrierTypeUrl = domain + "update-interstate-carrier-type";
export const addInternationalCarrierTypeUrl = domain + "add-international-carrier-type";
export const getAssignedParcelflowRatesUrl = domain + "carriers/get-assigned-parcelflow-rates.php";
export const createZoneProfileUrl = domain + "zones/create-zone-profile";
export const getAllZonesProfileUrl = domain + "zones/get-all-zone-profiles";
export const getAllZoneProfilePricingUrl = domain + "zones/get-all-zones-pricing-by-profile";
export const getCustomersReportUrl = domain + "get-customers-report";
export const getTransactionsReportUrl = domain + "get-transactions-report";